import { Overlay } from 'components/Layout';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from "react-transition-group";
import * as Routes from 'routes';
import styled from 'styled-components';
import { AuthHeader, ResetPassword, SignUp } from '.';
import AuthFooter from './AuthFooter';
import ForgotPassword from './ForgotPassword';

const Root = styled.div`
  width: 100%;
  height: 100vh;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: ${p => p.theme.zIndex.lg};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (min-width: ${p => p.theme.screen.md}) {
    justify-content: center;
  }
`;

const Pages = styled.div`
  margin-top: 80px;

  @media (min-width: ${p => p.theme.screen.md}) {
    margin-top: -120px;
  }
`;

const PeopleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const People = styled.div`
  width: 25vw;
  display: block;
  height: 25vh;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: 1500px) {
    background-size: cover !important;
  }
`;

const Cat = styled.div`
  background-image: url('./images/cat-face.png');
  width: 25vw;
  display: block;
  height: 25vh;
  background-size: cover;
  background-position: center center;
`;

const ImageArray = (props) => {
  const [flipped, setFlipped] = useState(false);
  const [count, setCount] = useState(100);
  const newRats = [];

  for (let i = 0; i < 24; i++) {
    newRats.push(i);
  }

  const updateCount = () => {
    setCount(count + 1);
    if (count > 300) {
      setFlipped(true);
    }
  }

  useEffect(() => {
    if (flipped) {
      setTimeout(() => {
        setFlipped(false);
        setCount(100);
      }, 100)
    }
  }, [flipped])

  useEffect(() => {
    if (count > 110 && count < 300) {
      setCount(count + 10);
    }
  }, [count])

  return newRats.map((item, i) => {
    let divstyle;
    if (i < 10) {
      divstyle = { backgroundImage: `url(./images/rats_0${+ i}.jpg)` }
    } else {
      divstyle = { backgroundImage: `url(./images/rats_${i}.jpg)` }
    }

    return !flipped ? (
      <SwitchTransition>
        <CSSTransition
          key={flipped ? "Goodbye, world!" : "Hello, world!"}
          addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}

        >
          <People key={i}
            onClick={updateCount}
            style={{ backgroundSize: `${count}%`, ...divstyle }}
          ></People>
        </CSSTransition>
      </SwitchTransition>
    ) : <Cat key={i}
      style={{}} onClick={updateCount}></Cat>
  });
}

/**
 * Main Layout for the app, when user isn't authenticated
 */

const AuthLayout = ({ refetch }) => {
  return (
    <>
      <Root>
        <Overlay transparency="0.5" />
        <PeopleWrapper><ImageArray></ImageArray></PeopleWrapper>
        <Container>
          <AuthHeader refetch={refetch} />
          <Pages>
            <Switch>
              <Route
                exact
                path={Routes.HOME}
                render={() => <SignUp refetch={refetch} />}
              />
              <Route
                exact
                path={Routes.FORGOT_PASSWORD}
                component={ForgotPassword}
              />
              <Route
                exact
                path={Routes.RESET_PASSWORD}
                render={() => <ResetPassword refetch={refetch} />}
              />
              <Redirect to={Routes.HOME} />
            </Switch>

          </Pages>
          <AuthFooter></AuthFooter>
          <Cat
            style={{ visibility: `hidden` }}></Cat>
        </Container>
      </Root>
    </>
  );
};

AuthLayout.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default AuthLayout;
