import { Container } from 'components/Layout';
import { RegularA } from 'components/Text';
import React from 'react';
import styled from 'styled-components';




const Root = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  background-color: transparent;
  color: ${p => p.theme.colors.white};
`;

const StyledContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 ${p => p.theme.spacing.sm};

  @media (min-width: ${p => p.theme.screen.md}) {
    justify-content: flex-end;
  }
`;

const LogoFooter = styled(RegularA)`
  color: ${p => p.theme.colors.white};
  font-size: ${p => p.theme.font.size.sm};
  font-weight: ${p => p.theme.font.weight.bold};
  &:hover {
    color: ${p => p.theme.colors.white};
  }
`;

/**
 * Header of the App when user isn't authenticated
 */
const AuthFooter = ({ refetch }) => {
  return (
    <Root>
      <StyledContainer maxWidth="lg">
        <p>&copy; 2020 <LogoFooter href="https://intesseract.io" target="_blank">Intesseract</LogoFooter></p>
      </StyledContainer>
    </Root>
  );
};

export default AuthFooter;
